import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LandingPageContact = () => {
  const [formData, setFormData] = useState({
    customertype: "",
    name: "",
    email: "",
    phone: "",
    bill: "",
    city: "",
    pincode: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // For loading state

  const navigate = useNavigate(); // Hook for navigation

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value.trimStart(),
    });
    setErrors({ ...errors, [name]: "" }); // Clear the error as the user types
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.customertype)
      newErrors.customertype = "Customer Type is required.";
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Invalid email format.";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^[6-9]\d{9}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    if (!formData.bill.trim()) newErrors.bill = "Electricity bill is required.";
    if (!formData.city.trim()) newErrors.city = "City is required.";
    if (!formData.pincode.trim()) {
      newErrors.pincode = "Pincode is required.";
    } else if (!/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Pincode must be a 6-digit number.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fieldErrors = validateFields();
    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors);
      return;
    }
    const trimmedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value.trim()])
    );
    setLoading(true); // Show loader
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}admin/SendCampaignLeadForm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(trimmedFormData),
        }
      );
      const res = await response.json();
      if (res.status === 200) {
        // Redirect to Thank You page
        navigate("/lead-thank-you");
      } else {
        const errorData = await response.json();
        alert(errorData.msg || "Error submitting the form. Please try again.");
      }
    } catch (error) {
      setErrors({ form: "Error connecting to the server." });
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div>
      <div className="bg-black bg-opacity-75 shadow-lg rounded-lg p-6 lg:p-8 lg:mt-[-12rem] max-[1000px]:mt-[-7rem]">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-center text-white">
            Get In Touch
          </h2>
          {/* Customer Type Radio Buttons */}
          <div className="relative">
            <div className="flex gap-4">
              <label className="flex items-center text-white text-[1.1rem]">
                <input
                  type="radio"
                  name="customertype"
                  value="Residential"
                  checked={formData.customertype === "Residential"}
                  onChange={handleChange}
                  className="mr-2 border-gray-300 w-5 h-5"
                />
                Residential
              </label>
              <label className="flex items-center text-white text-[1.1rem]">
                <input
                  type="radio"
                  name="customertype"
                  value="Commercial"
                  checked={formData.customertype === "Commercial"}
                  onChange={handleChange}
                  className="mr-2 border-gray-300 w-5 h-5"
                />
                Commercial
              </label>
            </div>
            {errors.customertype && (
              <p className="text-red-500 text-[12px] absolute">
                {errors.customertype}
              </p>
            )}
          </div>

          {/* Name Field */}
          <div className="relative">
            <input
              type="text"
              name="name"
              placeholder="Name *"
              value={formData.name}
              onChange={handleChange}
              className={`w-full border p-2 rounded-lg ${
                errors.name ? "border-red-500" : "border-gray-300"
              } text-gray-700 bg-white placeholder-gray-500`}
            />
            {errors.name && (
              <p className="text-red-500 text-[12px] absolute">{errors.name}</p>
            )}
          </div>
          {/* Email Field */}
          <div className="relative">
            <input
              type="email"
              name="email"
              placeholder="Email *"
              value={formData.email}
              onChange={handleChange}
              className={`w-full border p-2 rounded-lg ${
                errors.email ? "border-red-500" : "border-gray-300"
              } text-gray-700 bg-white placeholder-gray-500`}
            />

            {errors.email && (
              <p className="text-red-500 text-[12px] absolute">
                {errors.email}
              </p>
            )}
          </div>
          {/* Phone Field */}
          <div className="relative">
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number *"
              value={formData.phone}
              onChange={handleChange}
              className={`w-full border p-2 rounded-lg ${
                errors.phone ? "border-red-500" : "border-gray-300"
              } text-gray-700 bg-white placeholder-gray-500`}
            />

            {errors.phone && (
              <p className="text-red-500 text-[12px] absolute">
                {errors.phone}
              </p>
            )}
          </div>
          {/* Electricity Bill Field */}
          <div className="relative">
            <input
              type="number"
              name="bill"
              placeholder="Average Electricity Bill *"
              value={formData.bill}
              onChange={handleChange}
              className={`w-full border p-2 rounded-lg ${
                errors.bill ? "border-red-500" : "border-gray-300"
              } text-gray-700 bg-white placeholder-gray-500`}
            />

            {errors.bill && (
              <p className="text-red-500 text-[12px] absolute">{errors.bill}</p>
            )}
          </div>
          {/* City Field */}
          <div className="relative">
            <input
              type="text"
              name="city"
              placeholder="Enter Your City *"
              value={formData.city}
              onChange={handleChange}
              className={`w-full border p-2 rounded-lg ${
                errors.city ? "border-red-500" : "border-gray-300"
              } text-gray-700 bg-white placeholder-gray-500`}
            />

            {errors.city && (
              <p className="text-red-500 text-[12px] absolute">{errors.city}</p>
            )}
          </div>
          {/*----pincode----*/}
          <div className="relative">
            <input
              type="text"
              name="pincode"
              placeholder="Enter Your Pincode *"
              value={formData.pincode}
              onChange={handleChange}
              className={`w-full border p-2 rounded-lg ${
                errors.pincode ? "border-red-500" : "border-gray-300"
              } text-gray-700 bg-white placeholder-gray-500`}
            />

            {errors.pincode && (
              <p className="text-red-500 text-[12px] absolute">
                {errors.pincode}
              </p>
            )}
          </div>

          {/*---submit button----*/}
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-700 text-white py-2  rounded-lg hover:bg-blue-800"
          >
            {loading ? "Please Wait..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LandingPageContact;
